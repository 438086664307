@use '../../../styles/mixins.scss' as *;

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.column {
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include tablet {
    width: 300px;
  }

  @include desktop {
    width: 300px;
  }
}
