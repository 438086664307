@use '../../../styles/mixins.scss' as *;

.wrapper {
  font-size: 16px;
  border: none;
  border-radius: var(--border-radius);
  padding: 11px 29px;
  width: calc(100dvw - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @include tablet {
    width: 482px;
  }

  @include desktop {
    width: 482px;
  }
}

.primary {
  color: #ffffff;
  background-color: var(--items-background-color);
}
