.imageWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}

.fieldGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
