@use '../../../styles/mixins.scss' as *;

.wrapper {
  width: calc(100% - 50px);
  max-height: auto;
  background-color: var(--third-background-color);
  margin-bottom: 40px;
  padding: 14px;
  margin-left: 25px;
  margin-right: 25px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);

  @include tablet {
    width: 482px;
  }

  @include desktop {
    width: 482px;
  }
}
