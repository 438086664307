@use '../../styles/mixins.scss' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-green);
  font-size: 14px;
  line-height: 29px;
  padding: 14px;
  line-height: 140%;

  @include desktop {
    width: 100%;
    font-size: 20px;
    line-height: 29px;
  }
}

.container {
  width: calc(100dvw - 50px);
  background-color: var(--third-background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 25px 20px 20px 20px;

  p {
    white-space: pre-wrap;
    margin-bottom: 15px;
  }

  @include tablet {
    width: 482px;
    padding: 25px 80px;
  }

  @include desktop {
    width: 482px;
    padding: 25px 80px;
  }
}
