@use './mixins.scss' as *;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body,
html {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 12px;

  @include desktop {
    font-size: 16px;
  }
}

body {
  background-color: var(--background-color);
  color: var(--text-bold-color);
  transition: all 0.3s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader img {
  animation: rotate 1s linear infinite;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 100dvh;
}

:root {
  --color-text: #6e7caf;
  --color-red: #ff857e;
  --color-gray: #ddf0e2;
  --color-blue: #567bff;
  --color-border: #d7e0ff;
  --color-light-blue: #f4f6ff;
  --color-white: #ffffff;
  --color-green: #0eaf00;
  --color-gold-light: rgba(251, 236, 159, 0.37);
  --color-gold-dark: #ffcd6d;
  --color-input: #ffffff;
  --border-radius: 10px;
  --border-radius-2: 4px;
  --color-background: #ecf4ff;
}

[data-theme='light'] {
  --color-blue: #567bff;
  --color-background: #ecf4ff;
  --color-white: #ffffff;
  --text-color: #6e7caf;
  --text-bold-color: #ffffff;
  --second-text-color: #6e7caf;
  --border-color: #d7e0ff;
  --third-background-color: #ecf4ff;
  --fields-background-color: #ffffff;
  --items-background-color: #2683ed;
}

[data-theme='dark'] {
  --color-blue: #2683ed;
  --background-color: #0e1827;
  --color-white: #ffffff;
  --text-color: #ffffff;
  --text-bold-color: #ffffff;
  --second-text-color: #828bad;
  --border-color: #1e2b3e;
  --third-background-color: #141e2d;
  --fields-background-color: #1e2b3e;
  --items-background-color: #2683ed;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
}

button {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

*:disabled {
  cursor: not-allowed;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  hyphens: auto;
}

img {
  max-width: 100%;
}
