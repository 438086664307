.wrapper {
  font-size: 16px;
  border: none;
  border-radius: var(--border-radius);
  padding: 11px 29px;
  width: 100%;

  box-shadow: 0px 3.28px 9.85px 0px #b5b5d21f;
  box-shadow: 0px 4px 20px 0px #1249ec33;
}

.primary {
  color: #ffffff;
  background-color: var(--items-background-color);
}

.secondary {
  color: var(--color-red);
  background-color: transparent;
  outline: 1px solid var(--color-red);
}

.success {
  color: #ffffff;
  background-color: var(--color-green);
}

.error {
  color: #ffffff;
  background-color: var(--color-red);
}

.outlined {
  background-color: transparent;
  border: 1px solid var(--color-red);
  color: var(--color-red);
}
