@use '../../../styles/mixins.scss' as *;

.headerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  background-color: var(--items-background-color);
  color: var(--color-white);
  padding: 20px 25px 16px 25px;
}

.headerGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;

  @include tablet {
    width: 482px;
  }

  @include desktop {
    width: 482px;
  }
}

.title {
  display: flex;
  align-items: center;

  h3 {
    font-size: 20px;
    font-weight: 600;

    @include desktop {
      font-size: 24px;
    }
  }

  img {
    margin-right: 10px;
  }
}

.timer {
  font-size: 20px;
  color: var(--color-white);
  font-weight: 500;

  @include desktop {
    font-size: 24px;
  }
}

.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;

  @include tablet {
    width: 482px;
  }

  @include desktop {
    width: 482px;
  }
}

.blob {
  position: absolute;
  right: 20px;
  top: 0;
  width: 30px;
  height: 20px;
  background: linear-gradient(
    90deg,
    rgba(38, 131, 237, 0) 0%,
    rgba(38, 131, 237, 1) 100%
  );
  z-index: 2;
}

.content {
  display: flex;
}

.overflowText {
  font-weight: 300;
  width: 52vw;
  overflow: hidden;
  white-space: nowrap;

  @include tablet {
    width: 260px;
  }

  @include desktop {
    width: 240px;
  }
}

.copy {
  background-color: white;
  padding: 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  img {
    color: var(--color-blue);
  }
}
