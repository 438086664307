@use '../../styles/mixins.scss' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container {
  width: calc(100dvw - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  background-color: var(--third-background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 16px;

  @include tablet {
    width: 482px;
    padding: 22px 80px;
  }

  @include desktop {
    width: 482px;
    padding: 22px 80px;
  }
}

.btnWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: var(--items-background-color);
}
