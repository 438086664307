/* QUERIES */
@mixin tablet() {
  @media only screen and (min-width: 541px) and (max-width: 1200px) {
    @content;
  }
}

@mixin mobile() {
  @media only screen and (max-width: 540px) {
    @content;
  }
}

@mixin desktop() {
  @media only screen and (min-width: 1201px) and (any-hover: hover) {
    @content;
  }
}

@mixin landscape() {
  @media (orientation: landscape) and (min-width: 400px) and (max-width: 1201px) and (any-hover: none) {
    @content;
  }
}

/* WRAPPER */
@mixin wrapper {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
