@use '../../../styles/mixins.scss' as *;

.wrapper {
  display: flex;
  width: 100%;
}

.field {
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  gap: 12px;
  color: var(--text-color);
  background-color: var(--fields-background-color);
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  padding: 13px 16px;
  outline: none;
  border: none;
  font-weight: 400;

  @include desktop {
    height: 46px;
  }
}

.text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copyWidth {
  width: calc(100% - 40px);

  @include desktop {
    width: calc(100% - 48px);
  }
}

.fullBorder {
  border-radius: var(--border-radius);
}

.bold {
  font-weight: 500;
}

.copy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--items-background-color);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  cursor: pointer;

  @include tablet {
    width: 40px;
    height: 40px;
  }

  @include desktop {
    width: 48px;
    height: 46px;
  }
}

.copy img {
  stroke: var(--color-white);
}

.center {
  display: flex;
  justify-content: center;

  .text {
    width: auto;
  }
}
