@use '../../../styles/mixins.scss' as *;

.wrapper li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  margin-left: 39px;
  width: calc(100% - 39px);
  border: 12px;
  border-radius: 9px;
  counter-increment: item 1;
  margin-bottom: 8px;
  color: var(--text-color);
  background-color: var(--fields-background-color);
  font-size: 12px;
  line-height: 17.3px;

  @include desktop {
    font-size: 16px;
    line-height: 23px;
    padding: 14px;
  }
}

.wrapper ul li::before {
  content: counter(item);
  position: absolute;
  left: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #ffffff;
  font-size: 14px;
  background-color: var(--color-blue);
}
