@use '../../styles/mixins.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.supportWrapper {
  margin-bottom: 28px;
}

.headWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.id {
  font-size: 9px;
  margin-bottom: 14px;
  color: var(--second-text-color);

  @include desktop {
    font-size: 14px;
  }
}

.fieldGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
}

.errorText {
  color: var(--color-red);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 290px;
  line-height: 20.23px;
}
